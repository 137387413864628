export default {
    data() {

        return {

            Language: [
            { name: 'English', code: 'en', flag: '🇺🇸' },
            { name: 'Spanish', code: 'es', flag: '🇪🇸' },
            { name: 'French', code: 'fr', flag: '🇫🇷' },
            { name: 'German', code: 'de', flag: '🇩🇪' },
            { name: 'Italian', code: 'it', flag: '🇮🇹' },
            { name: 'Portuguese', code: 'pt', flag: '🇵🇹' },
            { name: 'Russian', code: 'ru', flag: '🇷🇺' },
            { name: 'Chinese', code: 'zh', flag: '🇨🇳' },
            { name: 'Japanese', code: 'ja', flag: '🇯🇵' },
            { name: 'Korean', code: 'ko', flag: '🇰🇷' },
          ],
          
        }

    },
    methods: {

        checkEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
            .then(() => {
                // console.log('Text copied to clipboard');
            })
            .catch(err => {
                // console.error('Failed to copy text: ', err);
            });
        },
        
        isActiveTab(tabName) {
            return this.$route.name === tabName;
        },

        truncateName(name) {
            if (typeof name === 'string') {
                return name.length > 20 ? name.substring(0, 20) + '...' : name;
            } else {
                return name;
            }
        },

        getImage(icon) {
            return require('@/assets/' + icon);
        },
        getProfilePic(icon) {
            return 'https://api.donton.net/files/profile/' + icon;
        },

        formatWallet (walletaddress, letters = 6) {
            // if(wallet.>40) {
            if(letters>30 || letters<2 || !letters) letters=6;
            return walletaddress.substring(0, (2+letters))+'...'+walletaddress.substr((42-letters));
            //} else return wallet;
        },

        formatDate(date) {
            // Create a new Date object for the current date
            const today = new Date();
            // Create a new Date object for the given date
            const givenDate = new Date(date);
            // Calculate the difference in milliseconds
            const diffInMs = Math.abs(today - givenDate);
            // Convert the difference from milliseconds to days, hours, and minutes
            const diffInDays = Math.floor(diffInMs / (24 * 60 * 60 * 1000));
            const diffInHours = Math.floor(diffInMs / (60 * 60 * 1000));
            const diffInMinutes = Math.floor(diffInMs / (60 * 1000));

            // Return the difference in days, hours, or minutes
            if (diffInDays > 0) {
                return diffInDays + ' days';
            } else if (diffInHours > 0) {
                return diffInHours + ' hours';
            } else {
                return diffInMinutes + ' minutes';
            }
        },

        formatNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }

    }
}