<template>

    <div class="ts z-50 text-sm uppercase bg-gradient-to-b text-center from-slate-700 to-slate-800 h-18 fixed bottom-0 left-0 right-0 flex flex-row justify-between border-t border-slate-600">
        <router-link :to="{ name: 'home' }" @click.native="doTutorial(7)" :class="{ 'bg-slate-700': isActiveTab('home') }" class="relative w-1/5 border-r border-slate-600 hover:bg-slate-600 pb-2">
            <div class="absolute bg-red-500 rounded-full w-2 h-2 top-2 left-2" v-if="TutorialShow && TutorialStep==7"></div>
            <div class="absolute bg-green-500 rounded-full w-2 h-2 top-2 left-2" v-else-if="Data.user_data.user_collect_wait==0"></div>
            <div class="h-9 mt-4 xs:h-7 xs:mt-2"><img src="@/assets/set/user.svg" class="w-7 h-7 mx-auto"></div>
            <div class="max-xs:hidden">Gang</div>
        </router-link>
        <router-link :to="{ name: 'quest' }" @click.native="doTutorial(5)" :class="{ 'bg-slate-700': isActiveTab('quest') }" class="relative w-1/5 border-r border-slate-600 hover:bg-slate-600 pb-2">
            <div class="absolute bg-red-500 rounded-full w-2 h-2 top-2 left-2" v-if="TutorialShow && TutorialStep==5"></div>
            <div class="h-9 mt-4 xs:h-7 xs:mt-2"><img src="@/assets/set/signal.svg" class="w-7 h-7 mx-auto"></div>
            <div class="max-xs:hidden">Jobs</div>
        </router-link>
        <!-- <router-link :to="{ name: 'fight' }" @click.native="doTutorial(1)" :class="{ 'bg-slate-700': isActiveTab('fight') }" class="relative w-1/5 border-r border-slate-600 hover:bg-slate-600 pb-2">
            <div class="absolute bg-red-500 rounded-full w-2 h-2 top-2 left-2" v-if="TutorialShow && TutorialStep==1"></div>
            <div class="h-9 mt-4 xs:h-7 xs:mt-2"><img src="@/assets/set/gun.svg" class="w-7 h-7 mx-auto"></div>
            <div class="max-xs:hidden">Attack</div>
        </router-link> -->
        <div @click="goAttack" :class="{ 'bg-slate-700': isActiveTab('fight') }" class="cursor-pointer relative w-1/5 border-r border-slate-600 hover:bg-slate-600 pb-2">
            <div class="absolute bg-red-500 rounded-full w-2 h-2 top-2 left-2" v-if="TutorialShow && TutorialStep==1"></div>
            <div class="h-9 mt-4 xs:h-7 xs:mt-2"><img src="@/assets/set/gun.svg" class="w-7 h-7 mx-auto"></div>
            <div class="max-xs:hidden">Attack</div>
        </div>
        <router-link :to="{ name: 'armory' }" @click.native="doTutorial(3)" :class="{ 'bg-slate-700': isActiveTab('armory') }" class="relative w-1/5 border-r border-slate-600 hover:bg-slate-600 pb-2">
            <div class="absolute bg-red-500 rounded-full w-2 h-2 top-2 left-2" v-if="TutorialShow && TutorialStep==3"></div>
            <div class="h-9 mt-4 xs:h-7 xs:mt-2"><img src="@/assets/set/ammo.svg" class="w-7 h-7 mx-auto"></div>
            <div class="max-xs:hidden">Armory</div>
        </router-link>
        <router-link :to="{ name: 'estate' }" :class="{ 'bg-slate-700': isActiveTab('estate') }" class="relative w-1/5 hover:bg-slate-600 pb-3">
            <div class="h-9 mt-4 xs:h-7 xs:mt-2"><img src="@/assets/set/city.svg" class="w-7 h-7 mx-auto"></div>
            <div class="max-xs:hidden">Invest</div>
        </router-link>
        <!-- <div @click="doMenu()" class="w-1/5 hover:bg-slate-600">
            <div class="h-9 mt-4 xs:h-7 xs:mt-3"><img src="@/assets/icon/menu.svg" class="w-7 h-7 mx-auto"></div>
            <div class="max-xs:hidden">Menu</div>
        </div> -->
        <!-- <div class="w-1/6 border-r border-slate-500 hover:bg-slate-600">
            <div class="h-7 mt-3"><img src="@/assets/icon/plane.svg" class="w-6 h-6 mx-auto"></div>
            <div>Travel</div>
        </div> -->
    </div>
</template>

<script>
import router from '@/router';
  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
        TutorialShow: Boolean,
        TutorialStep: Number,
    },
    data() {
        return {
            userData: {},
        }
    },
    mounted() {
        this.userData = this.Data.user_data;
    },
    methods: {
        doMenu() {
            this.$emit('doMenu');
        },
        goAttack() {
            if(this.TutorialShow) this.doTutorial(1);
            const isActive = this.isActiveTab('fight');
            if(isActive) {
                this.$emit('updateFight');
            } else {
                router.push({ name: 'fight' }); 
            }
        },
        doTutorial(step) {
            if(this.TutorialShow) {
                // alert('step='+step+', next_step='+next_step+', TutorialStep='+this.TutorialStep);
                if(this.TutorialStep==(step-1)) {
                    // alert('step-1 so='+step);
                    this.$emit('doTutorial', step);
                } 
                // else {
                //     if(this.TutorialStep==(next_step-1)) {
                //         alert('next step-1 so='+next_step);
                //         this.$emit('doTutorial', next_step);
                //     }
                // }
            }
        },

    }
}
</script>