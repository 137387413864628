<template>
    <div class="
    md:w-[80%] md:max-w-[680px] mx-auto
    border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl fixed top-14 mt-1 z-[45] rounded-md left-3 right-3">
        <div class="flex flex-row gap-2 p-3">
            <div>Level Up to increase and restore your HP, Stamina and Energy.</div>
            <button class="ml-auto but-4 but-c h-fit" @click="goLevelUp">Level Up</button>
            <button class="ml-auto but-5 but-b mb-6" @click="closeLevelUp">X</button>
            <!-- <router-link :to="{ name: 'levelup' }" class="ml-auto but-4 but-c" @click="closeLevelUp">Level Up</router-link> -->
        </div>
    </div> 
</template>

<script>
  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
    },
    data() {
        return {

        }
    },
    methods: {
        goLevelUp() {
            this.$router.push({ name: 'levelup' });
            this.$emit('closeLevelUp');
        },
        closeLevelUp() {
            this.$emit('closeLevelUp');
        }
    },
    mounted() {
        // this.Data.user_data = this.Data.user_data;
    },
}
</script>