<template>
    
    <!-- TOP MENU-->
    <div class="flex h-12 flex-row bg-gradient-to-b from-slate-700 to-slate-800 shadow-slate-950 shadow-md border-b border-slate-600 sticky top-0 z-40">
        <router-link :to="{ name: 'home' }" class="w-16 h-12 pt-2 pl-2"><img src="@/assets/logo/dtm.png" class="w-16 mx-auto"></router-link>
        <!-- <router-link :to="{ name: 'home' }" class="w-12 h-12 p-3"><img src="@/assets/icon/home.svg" class="h-5 w-5"></router-link> -->
        <div class="uppercase my-auto ts1 text-slate-200 font-bold text-lg text-center">
            <!-- <div class="mx-auto">Home</div> -->
            
        </div>
        <div class="flex-grow"></div>
        <div class="ml-2">
            <!-- <router-link :to="{ name: 'home', params: { 'scrollTo': '#collect' } }" class="bg-slate-200 w-fit mt-2 ml-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto flex flex-row gap-2"> -->
            <router-link :to="{ name: 'home', hash: '#collect' }" @click.native="doHideCollect()" class="bg-slate-200 w-fit flex flex-row gap-2 mt-2 ml-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto">
                <span v-if="updatingData">
                    <div class="spinner"></div>
                </span>
                <span class="" v-else>
                    <img src="@/assets/pics/cash.png" class="w-5 inline-block mr-2">
                    <span v-if="Data.user_data.user_collect_ready==1 && Data.user_data.user_profit>0 && !hideCollect" class="bg-green-700 bg-opacity-70 rounded-md px-1 py-0">+{{ Data.user_data.user_profit }}</span>
                    <span v-else>
                        <span class="text-white my-auto shrink-0">{{ Data.user_data.user_cash }}</span>   
                    </span>
                </span>
            </router-link>
        </div>
        <!-- <div class="">
            <router-link :to="{ name: 'profile' }" class="bg-slate-200 w-fit mt-2 ml-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto flex flex-row gap-2">
                    <img src="@/assets/icon/ton.svg" class="w-5">
                    <span class="text-white my-auto">{{ Data.user_data.user_ton }}</span>   
            </router-link>
        </div> -->
        <div class="">
            <router-link :to="{ name: 'shop' }" class="bg-slate-200 w-fit mt-2 ml-2 bg-opacity-10 rounded-md h-8 px-2 py-1 text-sm text-slate-200 my-auto flex flex-row gap-2">
                <img src="@/assets/pics/gem.png" class="w-5">
                <span class="text-white my-auto">{{ Data.user_data.user_gems }}</span>
                <div class="bg-slate-100 rounded-sm p-1 flex flex-row w-4 h-4 my-auto max-xs:hidden">
                    <img src="@/assets/icon/plus-b.svg" class="my-auto mx-auto">
                </div>
            </router-link>
        </div>
        <!-- <router-link :to="{ name: 'profile' }" class="ml-auto w-fit pt-2 max-xs:hidden"> -->
        <button @click="doUserProfile()" class="ml-auto w-fit pt-1">
            <div v-if="Data.user_data.user_pic && Data.user_data.user_pic != 'NO'">
                <img :src="getProfilePic(Data.user_data.user_pic)" class="ml-2 w-8 h-8 rounded-full mb-1 border border-slate-400">
            </div>
            <div v-else>
                <img src="@/assets/icon/wallet.svg" class="ml-2 w-6 rounded-full mb-1">
            </div>
        </button>
        <!-- </router-link> -->
        <div class="w-fit px-2 pr-3 pt-3" @click="doMenu()"><img src="@/assets/icon/menu.svg" class="h-6 w-6 cursor-pointer"></div>

    </div>
    
</template>

<script>

export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
        TutorialShow: Boolean,
        updatingData: Boolean,
    },
    data() {
        return {
            hideCollect: false,
        }
    },
    mounted() {
        this.Data.user_data.user_collect_ready = true;
    },
    methods: {
        doHideCollect() {
            this.hideCollect = true;
        },
        doMenu() {
            if(this.TutorialShow) this.$emit('doTutorial', 9);
            this.$emit('doMenu');
        },
        doUserProfile() {
            this.$emit('doUserProfile');
        },
    }
}

</script>